import * as React from 'react'
import { Form, Formik } from 'formik'
import { Button } from '@toasttab/buffet-pui-buttons'
import { PasswordFieldWithRequirements } from '@toasttab/buffet-pui-forms'
import {
  ContentFooter,
  ContinueButton,
  NavigationContainer
} from '@toasttab/buffet-pui-wizard-templates'
import { useMutationActivateUser } from '../../../../../src/hooks/useMutationActivateUser'

export interface UserActivationFormProps {
  passwordInputLabel: string
  orgName: string
}

export const UserActivationForm: React.FunctionComponent<
  UserActivationFormProps
> = ({ passwordInputLabel, orgName }) => {
  const [password, setPassword] = React.useState('')
  const { activateUserMutation } = useMutationActivateUser(orgName)
  return (
    <Formik
      initialValues={{ password }}
      validateOnChange
      onSubmit={(values) => {
        activateUserMutation(values.password)
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <div className='my-4'>
            <PasswordFieldWithRequirements
              label={passwordInputLabel}
              name='password'
              placeholder='Type password'
              useNewRequirements
            />
          </div>
          <p className='my-10 type-default text-gray-75'>
            By activating your account you agree to{' '}
            <Button
              as='a'
              variant={Button.Variant?.['text-link']}
              href='#'
              target='_blank'
            >
              Toast's End User Agreement
            </Button>{' '}
            acknowledge your information will be processed pursuant to Toast's
            privacy statement.
          </p>
          <ContentFooter>
            <NavigationContainer>
              <ContinueButton
                as='button'
                disabled={!(formik.isValid && formik.dirty)}
                testId={'activate-user-button'}
                type='submit'
              >
                Activate
              </ContinueButton>
            </NavigationContainer>
          </ContentFooter>
        </Form>
      )}
    </Formik>
  )
}
