import { useQuery } from 'react-query'
import { api } from '../api'

export const useSecurityKeyData = (securityKey: string) => {
  const {
    isLoading: securityKeyDataLoading,
    error: securityKeyDataError,
    data
  } = useQuery(['getSecurityKey', securityKey], () =>
    api.getSecurityKeyData(securityKey)
  )

  return {
    securityKeyData: data?.data,
    securityKeyDataLoading,
    securityKeyDataError
  }
}
