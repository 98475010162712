import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { AxiosError } from 'axios'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { DeveloperSecurityKeyType } from '@local/types'
import { ConfigurableErrorPage } from '@local/depot-error-pages'
import { UserActivationPage } from '@local/user-activation'
import { useSecurityKeyData } from '../../hooks/useSecurityKeyData'

/**
 * Retrieve security key data
 * - if success
 *   if type is INVITATION render the UserActivationPage
 *   if type is RESET_PASSWORD render ResetPasswordPage (TPC-3165)
 *
 * - if error
 *    render error page (TPC-3179): Key not found 404 // key expired 404? // rate limiting 429
 */
export const PageWrapper = () => {
  const location = useLocation()
  const securityKeyGuid =
    new URLSearchParams(location.search).get('securityKeyGuid') || ''
  const { securityKeyData, securityKeyDataLoading, securityKeyDataError } =
    useSecurityKeyData(securityKeyGuid)

  if (securityKeyDataLoading) {
    return (
      <div className='w-full h-full flex flex-row justify-center'>
        <MerryGoRound size='lg' testId='loading-data' />
      </div>
    )
  }

  if (securityKeyDataError) {
    const errorStatus = (securityKeyDataError as AxiosError)?.response?.status
    switch (errorStatus) {
      case 404:
        return <ConfigurableErrorPage errorType='EXPIRED_LINK' />
      case 429:
      case 500:
      default:
        return <ConfigurableErrorPage errorType='GENERIC_ERROR' />
    }
  }

  if (!!securityKeyData) {
    switch (securityKeyData.type) {
      case DeveloperSecurityKeyType.INVITATION:
        return <UserActivationPage securityKeyData={securityKeyData} />
      case DeveloperSecurityKeyType.RESET_PASSWORD:
        // [TPC-3165] if DeveloperSecurityKeyType is RESET_PASSWORD render ResetPasswordPage
        return <ConfigurableErrorPage errorType='NOT_FOUND' />
      default:
        return <ConfigurableErrorPage errorType='GENERIC_ERROR' />
    }
  }
  return <ConfigurableErrorPage errorType='GENERIC_ERROR' />
}
